.bg-pan-right {
  -webkit-animation: bg-pan-right 8s both;
  animation: bg-pan-right 8s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-5-11 13:18:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes bg-pan-right {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes bg-pan-right {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
