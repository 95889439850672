* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}
:root {
  --mainTextColor: #1e90ff;
  --secondaryTextColor: #030d16;
}
